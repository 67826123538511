import React, { useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import WhatWeDo from "./components/WhatWeDo";
import Staff from "./components/Staff";
import Contact from "./components/Contact";
import Splash from "./components/Splash";

const App = () => {
  const MainBody = () => {
    const sectionSplash = useRef(null);
    const sectionWWD = useRef(null);
    const sectionStaff = useRef(null);
    const sectionContact = useRef(null);

    const scrollToSection = (section) => {
      switch (section) {
        case "Splash":
          sectionSplash.current.scrollIntoView();
          break;
        case "WWD":
          sectionWWD.current.scrollIntoView();
          break;
        case "Staff":
          sectionStaff.current.scrollIntoView();
          break;
        case "Contact":
          sectionContact.current.scrollIntoView();
          break;
        default:
          break;
      }
    };

    return (
      <>
        <div ref={sectionSplash}>
          <Splash />
        </div>
      </>
    );
  };

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <MainBody />
    </ThemeProvider>
  );
};

export default App;
