import React from "react";
import { SocialIcon } from "react-social-icons";

const Splash = () => {
  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <video
        id="background-video"
        loop
        autoPlay
        muted
        defaultMuted playsinline oncontextmenu="return false;" preload="auto"  
        style={{ position: "absolute", minHeight: "100%", minWidth: "100%" }}
      >
        <source src="/herovid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        style={{
          background: "rgba(0,0,0,0.6)",
          height: "100%",
          width: "100%",
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <img
            src="/logo.png"
            alt="Conexión Canarias"
            style={{
              display: "block",
              height: 80,
            }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 24,
              justifyContent: "space-between",
            }}
          >
            <SocialIcon url={"https://www.facebook.com/ConexionCanarias"} />
            <SocialIcon url={"https://twitter.com/ConexCanarias"} />
            <SocialIcon url={"https://www.instagram.com/conexioncanarias/"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Splash;
